export const projects = {
  model: {
    create: {
      url: "admin/project/create/step_one",
      method: "POST",
      data: null,
    },
    update: {
      url: "admin/project/update",
      method: "POST",
      id: null,
      data: null,
    },
    get: {
      url: "admin/project/get",
      method: "GET",
      id: null,
      params: null,
    },
    uploadPhoto: {
      url: "v2/project/uploadPhoto/",
      method: "PUT",
      id: null,
      params: null,
      data: null,
    },
    uploadCoverImage: {
      url: "project/uploadCoverImage",
      method: "POST",
      id: null,
      data: null,
    },
    topratted: {
      url: "project/gettoprated",
      method: "GET",
      id: null,
      params: null,
    },
    search: {
      url: "project/find/",
      method: "GET",
      id: null,
      params: null,
    },
    delete: {
      url: "admin/project/delete/",
      method: "DELETE",
      id: null,
    },
    changerating: {
      url: "project/create/toprated",
      method: "POST",
      data: null,
    },
    unhideCertificate: {
      url: "admin/project/unhideCertificate",
      method: "PUT",
      data: null,
    },
    hideCertificate: {
      url: "admin/project/hideCertificate",
      method: "PUT",
      data: null,
    },
    hide: {
      url: "admin/project/hide/",
      method: "DELETE",
      id: null,
    },
    addimgImages: {
      url: "project/uploadPhoto/",
      method: "PUT",
      id: null,
      data: null,
    },
    approve: {
      url: "project/approve/",
      method: "PUT",
      id: null,
      data: undefined,
    },
    update_bcc: {
      url: "admin/project/changeBCCNumber/",
      method: "PUT",
      id: null,
      data: undefined,
    },
  },
  status: {
    get: {
      url: "projectstatus",
      method: "GET",
      id: null,
      params: null,
    },
  },
  updateProjectRating: {
    url: "project/updateProjectRating/",
    method: "PUT",
    id: null,
  },
  getListOfWorkedUnder: {
    url: "project/getListOfWorkedUnder",
    method: "GET",
    params: null,
  },
  addMember: {
    url: "admin/project/addMember",
    method: "POST",
    data: null,
  },
  addCompany: {
    url: "admin/project/linkCompany",
    method: "POST",
    data: null,
  },
  exportToCsv: {
    url: "admin/project/export_to_csv",
    method: "GET",
    data: null,
  },
  remove_cover_image: {
    url: "admin/project/removeCoverImage/",
    method: "DELETE",
    data: null,
    id: null,
  },
  projectStatus: {
    url: "admin/projectstatus/",
    method: "GET",
    data: null,
    id: null,
  },
  changeStatus: {
    url: "v2/admin/project/changeStatus/",
    method: "PUT",
    data: null,
    id: null,
  },
  getProjectsUserWorkedOn: {
    url: "admin/project/getProjectsUserWorkedOn/",
    method: "GET",
    data: null,
    id: null,
  },
  editClaimProject: {
    url: "admin/project/editClaimProject",
    method: "POST",
    data: null,
    id: null,
  },
  getStages: {
    url: "stage/get",
    method: "GET",
    data: null,
    id: null,
  },
};
