<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Orders</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :busy="loader"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader
                          color="#505d69"
                          :loading="true"
                        ></pulse-loader>
                      </div>
                    </template>
                    <template v-slot:cell(paymentstatus)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Chargeback',
                          'badge-soft-success': `${row.value}` === 'Paid',
                          'badge-soft-warning': `${row.value}` === 'Unpaid',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(rank)="row">
                      <div class="rank-edit-wrapper">
                        <FormulateForm
                          v-model="values[row.item.project_id._id]"
                          style="display: flex"
                        >
                          <FormulateInput
                            type="number"
                            name="rank"
                            style="width: 84px; margin-right: 8px"
                            :value="row.item.rank"
                            validation="number|between:1,5"
                            :disabled="!(editRank === row.item._id)"
                          />

                          <FormulateInput
                            v-if="editRank === row.item._id"
                            style="width: 68px"
                            type="submit"
                            :label="changingRank ? 'Submitting...' : 'Submit'"
                            @click="formSubmit(row.item.project_id._id)"
                          />
                          <FormulateInput
                            v-else
                            @click="flipInputFieldStatus(row.item._id)"
                            style="width: 68px"
                            type="button"
                            label="Edit"
                          />
                        </FormulateForm>
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        :href="`/project-info?id=${row.item.project_id.project_id}`"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        title="View"
                      >
                        <i class="ri-eye-line font-size-18"></i>
                      </a>
                      <a
                        :href="`/edit-projects?id=${row.item.project_id.project_id}`"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { projects } from "@/config/api/projects";
/**
 * Project Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Projects",
      items: [
        {
          text: "All",
        },
        {
          text: "Projects",
          active: true,
        },
      ],
      loader: false,
      changingRank: false,
      editRank: null,
      values: {},
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        {
          key: "project_id.project_id",
          sortable: true,
          label: "Project Number",
        },
        { key: "project_id.name_en", sortable: true, label: "Name" },
        { key: "rank", sortable: true, label: "Rank" },

        { key: "action" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.ordersData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.ordersData.length;
    this.loadData();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    loadData() {
      this.loader = true;
      const api = projects.model.topratted;
      this.generateAPI(api)
        .then((res) => {
          this.ordersData = res.data.projects;
        })
        .finally(() => {
          this.loader = false;
        });
    },
    flipInputFieldStatus(id) {
      this.editRank = id;
      return;
    },
    formSubmit(id) {
      if (this.values[id].rank >= 0 && this.values[id].rank <= 10) {
        const api = projects.model.changerating;
        api.data = {
          rank: this.values[id].rank,
          project_id: id,
        };
        this.changingRank = true;
        this.generateAPI(api)
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err.response.data);
          })
          .finally(() => {
            this.changingRank = false;
            this.editRank = null;
            this.loadData();
          });
      } else {
        return;
      }
    },
  },
};
</script>


<style>
.rank-edit-wrapper {
  width: 148px;
}
.rank-edit-wrapper input,
.rank-edit-wrapper button {
  height: 24px;
}
.rank-edit-wrapper .formulate-input {
  margin-bottom: 0px;
}
</style>